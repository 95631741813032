.navbar_inner {
    background: transparent;
    background-color: #ededede6 !important;
}



@media only screen and (max-width: 768px) {
    .mobile_nav {
        width: 75%;
        ;
    }
}